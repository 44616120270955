import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import Notification from "../../../components/notification/notification";
import FileUpload from "./upload_files";

const Edit_payment_entry_popup = ({ editePopup, row }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [allAccount, setAllAccount] = useState([]);
  const [allparty, setAllParty] = useState([]);
  const [addPymentEntry, setAddPymentEntry] = useState({
    invoice_id: "",
    po_id: "",
    pos_id: "",
    party_type: "",
    payment_type: "",
    entity_id: "",
    transaction_type: "",
    payment_date: "",
    amount: "",
    payment_method: "",
    description: "",
    prepared_by: "",
    paid_from_account_id: "",
    paid_to_account_id: "",
    payment_file: "",
    cheque_number: "",
    cheque_date:"",
    payee_name:"",
    
  });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allReceiveIds, setAllReceive] = useState([]);
  const [allInvoiceIds, setAllInvoiceID] = useState([]);
  const [allpoIds, setPoID] = useState([]);
  const [fromAccount, setFromAccount] = useState();
  const [toAccount, setToAccount] = useState();
  const [selectedParty, setSeletedParty] = useState();


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setAddPymentEntry((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [name]: value,
      };

      // Logic to set transaction_type based on PO ID and Invoice ID
      if (updatedDetails.payment_type === "Pay") {
        updatedDetails.transaction_type = "Payment";
      } else if (updatedDetails.payment_type === "Receive") {
        updatedDetails.transaction_type = "Advance";
      } else {
        updatedDetails.transaction_type = "Payment";
      }

      return updatedDetails;
    });
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    const user = getCookieValue("employee_fullname");

    setAddPymentEntry((prevDetails) => ({
      ...prevDetails,
      payment_file: uploadedFiles[0],
      prepared_by: user,
    }));
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleResetForm = () => {
    setAddPymentEntry({
      invoice_id: null,
      po_id: null,
      pos_id: null,
      party_type: "",
      payment_type: "",
      entity_id: "",
      transaction_type: "",
      payment_date: "",
      amount: "",
      payment_method: "",
      description: "",
      prepared_by: "",
      paid_from_account_id: "",
      paid_to_account_id: "",
      payment_file: "",
      cheque_number: "",
      cheque_date:"",
      payee_name:"",
    });

    setUploadedFiles([]);
    setFromAccount("");
    setToAccount("");
    setSeletedParty("");
    setIsResetConfirmationOpen(false);
  };

  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const getAccountData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      const formattedData = response.data.data.map((item) => ({
        id: item.account_id,
        code: item.account_code,
        name: item.account_name,
      }));

      setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const handleAccountTypeFromChange = (e) => {
    const { value } = e.target;

    // Find selected account type
    const selectedAccount = allAccount.find((type) => type.name === value);

    // Update state with selected account type details
    if (selectedAccount) {
      setFromAccount(value);

      setAddPymentEntry((prevState) => ({
        ...prevState,
        paid_from_account_id: selectedAccount.id,
      }));
    }
  };

  const handleAccountTypeToChange = (e) => {
    const { value } = e.target;

    // Find selected account type
    const selectedAccount = allAccount.find((type) => type.name === value);

    // Update state with selected account type details
    if (selectedAccount) {
      setToAccount(value);
      setAddPymentEntry((prevState) => ({
        ...prevState,
        paid_to_account_id: selectedAccount.id,
      }));
    }
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getAllPartyType = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPartyByPartyType`,
        {
          params: {
            party_type: addPymentEntry.party_type,
          },
        }
      );

      setAllParty(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomerTypeFromChange = (e) => {
    const selected = e.target.value;
    const selectedAccount = allparty.find(
      (type) => type.customer_id === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setSeletedParty(selected);
      setAddPymentEntry((prevState) => ({
        ...prevState,
        entity_id: selectedAccount.customer_id,
      }));
    } else {
      console.log("Selected account not found");
    }
  };

  const handleInvoiceIDChange = (e) => {
    const selected = e.target.value;
      setAddPymentEntry((prevState) => ({
        ...prevState,
        invoice_id: selected
      }));
   
  };

  const handlePOIDFromChange = (e) => {
    const selected = e.target.value; // Access the value from the event
    const selectedAccount = allpoIds.find((type) => type.po_id === selected);

    // Update state with selected account type details
    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        po_id: selectedAccount.po_id,
      }));
    } else {
      console.log("PO Id account not found");
    }
  };

  const handlePOSIDFromChange = (e) => {
    const selected = e.target.value; 
    const selectedAccount = allReceiveIds.find(
      (type) => type.reference_id === selected
    );

    if (selectedAccount) {
      setAddPymentEntry((prevState) => ({
        ...prevState,
        pos_id: selectedAccount.reference_id,
      }));
    } else {
      console.log("POS Id account not found");
    }
  };

  const handleSupplierTypeFromChange = (e) => {
    const selected = e.target.value; // Access the value from the event
    const selectedAccount = allparty.find(
      (type) => type.supplier_name === selected
    );

    // Update state with selected account type details
    if (selectedAccount) {
      setSeletedParty(selected);
      setAddPymentEntry((prevState) => ({
        ...prevState,
        entity_id: selectedAccount.supplier_id,
      }));
    } else {
      console.log("Selected account not found");
    }
  };

  const handleSaveForm = async () => {
      try {
        const response = await axios.put(
          `${backendUrl}/paymentEntry/update/${addPymentEntry.id}`,
          addPymentEntry,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setNotification({
          message: response.data.message,
          type: "success",
        });
  
        handleResetForm();
      } catch (error) {
        console.error("Error", error);
        setNotification({
          message: "saving purchase Invoice. Please try again.",
          type: "error",
        });
      }

  
    setIsSaveConfirmationOpen(false);
  };

  const getPoIds = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getUnpaidPOs`
      );
      setPoID(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  const getReceive = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getUnpaidReceivables`
      );

      setAllReceive(response.data);
    } catch (error) {
      console.error("Error fetching sample details:", error);
     
    }
  };

  const getPaymentEntryByPartyTypeAndEntityId = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPartyByPartyTypeAndEntityId/${addPymentEntry.party_type}/${addPymentEntry.entity_id}`,
        {
          params: {
            party_type: addPymentEntry.party_type, // You may or may not need this, depending on how your backend is structured
          },
        }
      );
      setSeletedParty(response.data.data[0].supplier_name)
      return response.data; // Return the data or handle it in the component
    } catch (error) {
      console.error('Error fetching payment entry:', error);
      // Handle error accordingly
    }
  };

  const getInvoiceID = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getInvoiceByPOId/${addPymentEntry.po_id}`
      );

      setAllInvoiceID(response.data.invoice);
    } catch (error) {
      console.error("Error fetching sample details:", error);
    }
  };

  useEffect(() => {
    if (row) {
      setFromAccount(row.paid_from_account_name);
      setToAccount(row.paid_to_account_name);
  
      // Format the date to 'YYYY-MM-DD'
      const paymentDate = new Date('2024-10-01').toISOString().split('T')[0];
  
      setAddPymentEntry((prevState) => ({
        ...prevState,
        ...row,
        payment_date: paymentDate, // '2024-10-01'
      }));
  
      console.log(row.prepared_date);
    }
  
    // Fetch data
    const fetchData = async () => {
      try {
        await getAccountData();
        await getPoIds();
        await getReceive();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  
  }, []);
  

  useEffect(() => {
    getPaymentEntryByPartyTypeAndEntityId()
    getAllPartyType();
  }, [addPymentEntry.party_type]);

  useEffect(() => {
    getInvoiceID();
  }, [addPymentEntry.po_id]);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Payment Entry</h2>
          <button
            type="button"
            onClick={editePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          <div className="items-center gap-8 mt-5">
            {/* Define a two-column grid */}
            <div className="grid grid-cols-2 gap-y-8 gap-x-16 text-lg">
              {/* Payment Type */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Type
                </label>
                <select
                  name="payment_type"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                  value={addPymentEntry.payment_type}
                >
                  <option value=""></option>
                  <option value="Receive">Receive</option>
                  <option value="Pay">Pay</option>
                  <option value="Internal Transfer">Internal Transfer</option>
                </select>
              </div>

              {/* Conditional rendering based on the selected payment type */}
              {addPymentEntry.payment_type === "Pay" && (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    PO ID
                  </label>
                  <select
                    name="po_id"
                    className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                    onChange={handlePOIDFromChange}
                    value={addPymentEntry.po_id}
                  >
                   <option value="" disabled>Select a PO ID</option>
                    {allpoIds.map((type) => (
                      <option key={type.po_id} value={type.po_id}>
                        {type.po_id}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {addPymentEntry.payment_type === "Pay" &&
                addPymentEntry.po_id && (
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Invoice ID
                    </label>
                    <select
                      name="invoice_id"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      value={addPymentEntry.invoice_id}
                      onChange={handleInvoiceIDChange}
                    >
                      <option value="" disabled >Select an Invoice</option>
                      {allInvoiceIds && allInvoiceIds.length > 0 ? (
                        allInvoiceIds.map((type) => (
                          <option key={type.invoice_id} value={type.invoice_id}>
                            {type.invoice_id}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No Invoices Available
                        </option>
                      )}
                    </select>
                  </div>
                )}

              {addPymentEntry.payment_type === "Receive" && (
                <div>
                  <label className="block text-gray-700 font-semibold mb-1">
                    POS ID
                  </label>
                  <select
                    name="pos_id"
                    className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                    onChange={handlePOSIDFromChange}
                    value={addPymentEntry.pos_id}
                  >
                    <option value="" disabled></option>
                    {allReceiveIds.map((type) => (
                      <option key={type.reference_id} value={type.reference_id}>
                        {type.reference_id}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Payment Method */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Payment Method
                </label>
                <select
                  name="payment_method"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                  value={addPymentEntry.payment_method}
                >
                  <option value=""></option>
                  <option value="Cash">Cash</option>  
                  <option value="Cheque">Cheque</option>
                </select>
              </div>

              {/* Hide Party Type and Party when payment_type is Internal Transfer */}
              {addPymentEntry.payment_type !== "Internal Transfer" && (
                <>
                  {/* Party Type */}
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Party Type
                    </label>
                    <select
                      name="party_type"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPymentEntry.party_type}
                    >
                      <option value=""></option>
                      <option value="Customer">Customer</option>
                      <option value="Supplier">Supplier</option>
                    </select>
                  </div>

                  {/* Customer Party & Supplier Party */}
                  {addPymentEntry.party_type === "Customer" ? (
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Customer Party
                      </label>
                      <select
                        name="selectedParty"
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                        onChange={handleCustomerTypeFromChange}
                        value={selectedParty}
                      >
                        <option value=""></option>
                        {allparty.map((type) => (
                          <option
                            key={type.customer_id}
                            value={type.customer_id}
                          >
                            {type.customer_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Supplier Party
                      </label>
                      <select
                        name="selectedParty"
                        className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                        onChange={handleSupplierTypeFromChange}
                        value={selectedParty}
                      >
                        <option value="" disabled></option>
                        {allparty.map((type) => (
                          <option key={type._id} value={type.supplier_name}>
                            {type.supplier_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </>
              )}

              {/* From Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Account From
                </label>
                <select
                  name="fromAccount"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  value={fromAccount}
                  onChange={handleAccountTypeFromChange}
                >
                  <option value="" disabled></option>
                  {allAccount.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* To Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Account To
                </label>
                <select
                  name="toAccount"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  value={toAccount}
                  onChange={handleAccountTypeToChange}
                >
                  <option value=""></option>
                  {allAccount.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Amount */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                  value={addPymentEntry.amount}
                />
              </div>

              {/* Payment Date */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="start_date"
                  className="block text-gray-700 font-semibold mb-1"
                >
                  Payment Date
                </label>
                <input
                  id="payment_date"
                  name="payment_date"
                  type="date"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleInputChange}
                  value={addPymentEntry.payment_date}
                />
              </div>

              {/* Description */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                  onChange={handleInputChange}
                  value={addPymentEntry.description}
                />
              </div>

              {addPymentEntry.payment_method === "Cheque" && (
                <>
                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Check Number
                    </label>
                    <input
                      type="text"
                      name="cheque_number"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPymentEntry.cheque_number}
                    />
                  </div>


                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Check Date
                    </label>
                    <input
                      type="date"
                      name="cheque_date"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPymentEntry.cheque_date}
                    />
                  </div>

                  <div>
                    <label className="block text-gray-700 font-semibold mb-1">
                      Payee Name
                    </label>
                    <input
                      type="text"
                      name="payee_name"
                      className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2"
                      onChange={handleInputChange}
                      value={addPymentEntry.payee_name}
                    />
                  </div>
                </>
              )}

            </div>

            {/* Document Upload Section */}
            {uploadedFiles.length === 0 && (
              <>
                <h1 className="text-2xl font-bold mt-10 mb-4">
                  Payment Document
                </h1>
                <button
                  type="button"
                  onClick={handleOpenModal}
                  className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-bold">
                      Click Here to Upload
                    </span>
                    <IoIosCloudUpload className="text-xl" />
                  </div>
                </button>
              </>
            )}
            {/* Display Uploaded Files */}
            {uploadedFiles.length > 0 && (
              <div className="mt-5">
                <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                {uploadedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                  >
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>
        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Edit_payment_entry_popup;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
