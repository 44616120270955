/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../components/permissions/permission";

const PendingJobs = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [data, setData] = useState([]);
  const [statusMap, setStatusMap] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedJobIndex, setSelectedJobIndex] = useState(null);
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/job/createdjobapproveORreject?status=PENDING`
    )
      .then((response) => response.json())
      .then((data) => {
        const extractedData = data.map((job) => ({
          _id: job._id,
          jobName: job.job_name,
          recipeDr: job.recipe_dr,
          recipeName: job.recipe_name,
          jobqty: job.job_quantity,
          unitSymbole: job.unit_symbol,
          jobplevel: job.job_priority_level,
          status: job.job_status,
        }));
        setData(extractedData);
        console.log("extractedData", extractedData);
      })
      .catch((error) => {
        console.error("Error fetching pending jobs:", error);
      });
  }, []);

  const handleStatusChange = (e, index) => {
    const selectedStatus = e.target.value;
    setStatusMap({ ...statusMap, [index]: selectedStatus });
    if (selectedStatus === "Reject") {
      setShowPopup(true);
      setSelectedJobIndex(index);
    } else if (selectedStatus === "Approve") {
      setShowApproveConfirmation(true);
      setSelectedJobIndex(index);
    }
  };

  const handleRejectReasonChange = (e) => {
    setRejectReason(e.target.value);
  };

  const handleRejectionConfirmation = () => {
    const jobId = data[selectedJobIndex]._id;
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/job/makeapprove?_id=${jobId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_status: "REJECTED",
          job_reject_reason: rejectReason,
          job_dr: "EMP-005",
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Job status updated to rejected successfully");
          setShowPopup(false);
          setRejectReason("");
        } else {
          console.error("Failed to update Job status to rejected");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleApproveConfirmation = () => {
    const jobId = data[selectedJobIndex]._id;
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/job/makeapprove?_id=${jobId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_status: "APPROVE",
          job_dr: "EMP-005",
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("Job status updated to approved successfully");
          setShowApproveConfirmation(false);
        } else {
          console.error("Failed to update Job status to approved");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getStatusColor = (index) => {
    switch (statusMap[index]) {
      case "Approve":
        return "bg-[#F5E450] bg-opacity-50";
      case "Reject":
        return "bg-[#F55050] bg-opacity-50";
      default:
        return "";
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const maxPageNumbers = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = data.slice(startIndex, endIndex);

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Pending Jobs
      </p>
      <div className="flex mt-10">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Job Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Recipe Dr.
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Recipe Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                View Units
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Job Priority Level
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((job, index) => (
              <tr key={job._id} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{job.jobName}</td>
                <td className="text-center">{job.recipeDr}</td>
                <td className="text-center">{job.recipeName}</td>
                <td className="text-center">View Units</td>
                <td className="text-center">{job.jobplevel}</td>
                <td>
                  {hasPermission(2450) && (
                    <select
                      className={`w-full py-1 px-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 font-bold ${getStatusColor(
                        index
                      )}`}
                      value={statusMap[index] || ""}
                      onChange={(e) => handleStatusChange(e, index)}
                    >
                      <option value="Pending">Select an option</option>
                      <option value="Approve">Approve</option>
                      <option value="Reject">Reject</option>
                    </select>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showApproveConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="mb-4">Are you sure you want to approve?</p>
            <div className="flex justify-end">
              <button
                onClick={handleApproveConfirmation}
                className="bg-green-600 text-white px-4 py-2 rounded-md mr-2"
              >
                Yes
              </button>
              <button
                onClick={() => setShowApproveConfirmation(false)}
                className="bg-gray-300 px-4 py-2 rounded-md"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              Mention the reason for rejection below
            </h2>
            <textarea
              value={rejectReason}
              onChange={handleRejectReasonChange}
              className="w-full border rounded-md p-2"
              rows={4}
              placeholder="Enter reason..."
            ></textarea>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md mr-4"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleRejectionConfirmation}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
    </div>
  );
};

export default PendingJobs;
