import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import Notification from "../../../components/notification/notification";
import FileUpload from "./upload_files";

const Add_purchase_invoice_popup = ({ togglePopup, resetTable }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [supplierName, setSupplierName] = useState([]);
  const [poID, setPOId] = useState([]);

  const [purchaseInvoice, setPurchaseInvoice] = useState({
    invoice_number: "",
    po_id: "",
    invoice_number: "",
    invoice_date: "",
    total_amount: "",
    transaction_type: "Purchase Invoice",
    entity_id: "",
    description: "",
    draft_by: "",
    invoiceFile: "",
    debit_account_id: "",
  });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allAccount, setAllAccount] = useState([]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Handle Input Change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPurchaseInvoice((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setPurchaseInvoice((prevDetails) => ({
      ...prevDetails,
      invoiceFile: uploadedFiles[0],
    }));
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Reset Form
  const handleResetForm = () => {
    setPurchaseInvoice({
      po_id: "",
      invoice_number: "",
      invoice_date: "",
      total_amount: "",
      transaction_type: "Purchase Invoice",
      entity_id: "",
      description: "",
      draft_by: "",
      invoiceFile: "",
      debit_account_id: "",
    });
    setUploadedFiles([]);

    setIsResetConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const getSupplierForPoId = async () => {
    console.log(purchaseInvoice.entity_id);
    try {
  
      // change this url only used testing
      const response = await axios.get(
        `${backendUrl}/payable/purchaseInvoice/getPOBySupplierId/${purchaseInvoice.entity_id}`
      );
      console.log(response.data);
      setPOId(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSuppliers = async () => {
    try {
      const params = {
        party_type: "Supplier",
      };

      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPartyByPartyType`,
        { params }
      );

      const formattedData = response.data.data.map((item) => ({
        supplier_id: item.supplier_id,
        supplier_name: item.supplier_name,
      }));

      setSupplierName(formattedData);

      //  setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const handlesetPoIDForSupplierChange = (e) => {
    const { value } = e.target;
    const selectedSupplier = supplierName.find(
      (type) => type.supplier_name === value
    );
    const user = getCookieValue("employee_fullname");

    if (selectedSupplier) {
      setPurchaseInvoice((prevDetails) => ({
        ...prevDetails,
        entity_id: selectedSupplier.supplier_id,
        draft_by: user,
      }));
    }
  };

  const handlePOInputChange = (e) => {
    const { value } = e.target;
    console.log(value);

    const selectedPOId = poID.find((type) => type.po_id === value);

    if (selectedPOId) {
      setPurchaseInvoice((prevDetails) => ({
        ...prevDetails,
        po_id: selectedPOId.po_id,
      }));
    }
  };

  // File State Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Save Form

  const handleSaveForm = async () => {
    console.log(purchaseInvoice);
    try {
      const response = await axios.post(
        `${backendUrl}/payable/purchaseInvoice/add`,
        purchaseInvoice,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log(response.data.message)
      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      console.error("Error", error);
      setNotification({
        message: error.response.data.error,
        type: "error",
      });
    }

    setIsSaveConfirmationOpen(false);
  };

  const getAllAccountData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      const formattedData = response.data.data.map((item) => ({
        id: item.account_id,
        code: item.account_code,
        name: item.account_name,
      }));
      console.log("-------ff---------");
      console.log(response.data);
      console.log("-------ff---------");

      setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };

  const handleAccountTypeFromChange = (e) => {
    const { value } = e.target;

    // Find selected account type
    const selectedAccount = allAccount.find((type) => type.name === value);

    // Update state with selected account type details
    if (selectedAccount) {
      setPurchaseInvoice((prevState) => ({
        ...prevState,
        debit_account_id: selectedAccount.id,
      }));
    }
  };

  useEffect(() => {
    getAllAccountData();
    getAllSuppliers();
  }, []);

  useEffect(() => {
    getSupplierForPoId();
  }, [purchaseInvoice.entity_id]);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[80%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Add Purchase Invoice</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          <div className="items-center gap-8 mt-5">
            {/* Define a two-column grid */}
            <div className="grid grid-cols-2 gap-y-8 gap-x-16 text-lg">
              {/* Supplier */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Supplier
                </label>
                <select
                  name="supplier_id"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500"
                  onChange={handlesetPoIDForSupplierChange}
                >
                  <option value=""></option>
                  {supplierName && supplierName.length > 0 ? (
                    supplierName.map((type) => (
                      <option key={type.supplier_id} value={type.supplier_name}>
                        {type.supplier_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No suppliers available</option>
                  )}
                </select>
              </div>
              {/* PO ID*/}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  PO ID
                </label>
                <select
                  name="payment_type"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500"
                  onChange={handlePOInputChange}
                  value={purchaseInvoice.po_id}
                >
                  <option value=""></option>
                  {poID && poID.length > 0 ? (
                    poID.map((type) => (
                      <option key={type.po_id} value={type.po_id}>
                        {type.po_id}
                      </option>
                    ))
                  ) : (
                    <option disabled>No PO ID available</option>
                  )}
                </select>
              </div>

              {/* Account */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Select Account
                </label>
                <select
                  name="account_type"
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 "
                  onChange={handleAccountTypeFromChange}
                >
                  <option value=""></option>
                  {allAccount.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Invoice No */}

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Invoice No
                </label>
                <input
                  type="text"
                  name="invoice_number"
                  value={purchaseInvoice.invoice_number}
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500"
                  onChange={handleInputChange}
                />
              </div>

              {/* Invoice Date */}

              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="start_date"
                  className="block text-gray-700 font-semibold mb-1"
                >
                  Invoice Date
                </label>
                <input
                  id="invoice_date"
                  name="invoice_date"
                  value={purchaseInvoice.invoice_date}
                  type="date"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleInputChange}
                />
              </div>

              {/* Tatal Amount */}

              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                Total Amount
                </label>
                <input
                  type="text"
                  name="total_amount"
                  value={purchaseInvoice.total_amount}
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500"
                  onChange={handleInputChange}
                />
              </div>

              {/* Description */}
              
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  value={purchaseInvoice.description}
                  className="w-full p-3 mt-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-green-500"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {uploadedFiles.length === 0 && (
              <>
                {/* Section for Document Upload */}
                <h1 className="text-2xl font-bold mt-10 mb-4">
                  Purchase Document
                </h1>
                <button
                  type="button"
                  onClick={handleOpenModal}
                  className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
                >
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-bold">
                      Click Here to Upload
                    </span>
                    <IoIosCloudUpload className="text-xl" />
                  </div>
                </button>
              </>
            )}

            {/* Display Uploaded Files */}
            {uploadedFiles.length > 0 && (
              <div className="mt-5">
                <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                {uploadedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                  >
                    <span>{file.name}</span>
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <FileUpload
                setUploadedFiles={setUploadedFiles}
                uploadedFiles={uploadedFiles}
                onClose={handleCloseModal}
              />
              <button
                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add_purchase_invoice_popup;

const getCookieValue = (cookieName) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};
