/** @format */

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import TableBidderEvaluation from "./biddersEvaluationTabel";
import DialogMemberDetails from "./memberDetailsDialog";
import DialogBidderDetails from "./bidderDetailsDialog";
import uploadImage from "../../../../Assets/icons/uploadImage.png";
import DialogMeetingReportUp from "./meetingReportUpDialog";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";

const Technical_Members = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchSup, setSearchSup] = useState("");
  const userId = Cookies.get("employee_no");
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [tenders, setTenders] = useState([]);
  const [committees, setCommittee] = useState([]);
  const [supliers, setSupliers] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [selectedTender, setSelectedTender] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [tednerId, setTenderId] = useState("");
  const [techEvaCom1, setTechEvaCom1] = useState("");
  const [techEvaCom, setTechEvaCom] = useState("");
  const [techEvaComName, setTechEvaComName] = useState("");
  const [tenderName, setTenderName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [itemCategoryData, setItemCategoryData] = useState([]); // change to tech evaluation
  const [quotations, setQuotations] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]); // State for criteria list
  const [criteriaName, setCriteriaName] = useState(""); // State for criteria name input
  const [percentage, setPercentage] = useState(""); // State for percentage input

  const [selectComStatus, setSelectComStatus] = useState("APPROVED");
  const [selectComType, setSelectComType] = useState(
    "TECHNICAL EVALUATION COMMITTEE"
  );

  const fetchSubjects = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/itemCategory/get-couple-category`
      );
      const data = await response.json();

      // Filter the data to only include items with item_category_name = "RAW"
      const filteredData = data.filter(
        (item) => item.item_category_name === "RAW"
      );

      setItemCategoryData(filteredData);
      console.log("Filtered Items:", filteredData);
    } catch (error) {
      console.error("Error fetching Item Category data:", error);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleAdd = async () => {
    if (!criteriaName || !percentage || !tednerId) {
      alert("Please fill in all fields and ensure you selected a tender.");
      return;
    }

    try {
      // Prepare the data to send to the API
      const criteriaData = {
        tender_id: tednerId,
        criteria: criteriaName,
        percentage: parseFloat(percentage),
      };

      // Make the POST request to add the criteria
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/add`,
        criteriaData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle successful response
      if (response.status === 200 || response.status === 201) {
        // Add the new criteria to the local state
        setCriteriaList((prevList) => [
          ...prevList,
          {
            id: Date.now(),
            categoryName: criteriaName,
            percentage: parseFloat(percentage),
          },
        ]);

        alert("Criteria successfully added.");

        // Clear the inputs
        setCriteriaName("");
        setPercentage("");
      } else {
        alert("Failed to add criteria. Please try again.");
      }
    } catch (error) {
      // Handle errors
      console.error("Error adding criteria:", error);
      alert("An error occurred while adding the criteria.");
    }
  };

  // Fetch criteria for the PR ID
  const fetchCriteria = async (tender_id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/evaluation_data/${tender_id}`
      );
      setCriteriaList(response.data);
      console.log("Criteria:", response.data);
    } catch (error) {
      console.error("Error fetching criteria:", error);
    }
  };

  // Fetch quotations based on the tender_id
  const fetchQuotations = async (tender_id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/supplychain/quatation/get-sent-quatation/${tender_id}`
      );
      setQuotations(response.data);
      console.log("Quotations:", response.data);
    } catch (error) {
      console.error("Error fetching quotations:", error);
    }
  };

  useEffect(() => {
    fetchQuotations();
    fetchCriteria();
  }, []);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      try {
        const response = await axios.get(
          `${REACT_APP_API_ENDPOINT}/v1/supplyChain/quatation/get-sent-quatation/${query}`
        );
        setTenders(response.data);
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setTenders([]);
      }
    } else {
      setTenders([]);
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (selectComStatus !== null) {
      fetchItemData();
    }
  }, [selectComStatus, selectComType]);

  const fetchItemData = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/committee/members/all/committees?status=${selectComStatus}&committee_title=${selectComType}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCommittee(data);
      console.log("Data", data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setTenders([]);
    setIsDropdownOpen(false);
    setIsCustomerDropdownOpen(false);
  };

  // Handle selecting a tender
  const handleOptionClick = (result) => {
    setSearchTerm(result.tender_id);
    setSelectedTender(result);
    setIsDropdownOpen(false);

    // Assign tender_id (Purchase Request No) and fetch data
    setTenderId(result.pr_id);
    setTenderName(result.title);
    setTechEvaCom1(result.TEC_committee_id);
    setTechEvaComName(result.TEC_committee_title);

    // Fetch suppliers (quotations) and criteria for the selected tender
    fetchQuotations(result.pr_id);
    fetchCriteria(result.pr_id);
  };

  const bidEvaluationSummaryReport = () => {
    navigate("/committess/bidEvaluationSummaryReportCreate", {
      state: selectedTender,
    });
  };

  const bidEvaluationSummaryReportView = () => {
    navigate("/committess/bidEvaluationSummaryReportView", {
      state: selectedTender,
    });
  };

  const createCommiteeMeetingReport = () => {
    navigate("/committess/committeeMeetingReportCreate", {
      state: selectedTender,
    });
  };

  const viewCommiteeMeetingReport = () => {
    navigate("/committess/committeeMeetingReportView", {
      state: selectedTender,
    });
  };

  const [appointingDate, setAppointingDate] = useState(null);

  const [showDialog, setShowDialog] = useState(false);
  const [detailsShowDialog, setDetailsShowDialog] = useState(false);
  const [showMeetingsDialog, setshowMeetingsDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDetailDialog = () => {
    setDetailsShowDialog(true);
  };

  const closeDetailDialog = () => {
    setDetailsShowDialog(false);
  };

  const openMeetingReportDialog = () => {
    setshowMeetingsDialog(true);
    console.log("Im here...");
  };

  const closeMeetingReportDialog = () => {
    setshowMeetingsDialog(false);
  };

  const handleAddCommittee = async () => {
    const postBody = {
      committee_id: techEvaCom,
      pr_id: tednerId,
      create_by: userId,
      create_at: formattedDate,
    };
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria/submit-tec-committee`,
        {
          method: "POST", // Make sure this is inside the fetch call
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postBody),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Success:", data);
        alert("Committee successfully added."); // Success alert message
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData);
        alert("Failed to add Committee. Please try again."); // Failure alert message
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      alert("An unexpected error occurred. Please try again."); // Error alert message for unexpected errors
    }
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <p className="text-[32px] font-sans ml-6 mt-6 font-bold text-[#071C50] ">
        Technical Evaluation Committee
      </p>

      <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-6 right-[1%]">
        <div className="min-h-[110px] min-w-[150px]">
          <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
            View Committee <br /> Member Details
          </h2>
        </div>
        <div className="flex items-center relative">
          <button
            onClick={openDialog}
            className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
          >
            Click Here
          </button>

          <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
        </div>

        {showDialog && (
          <DialogMemberDetails id={tednerId} onClose={closeDialog} />
        )}
      </div>
      <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-[35%] right-[1%]">
        <div className="min-h-[110px] min-w-[150px]">
          <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
            View Bidder <br /> Details
          </h2>
        </div>
        <div className="flex items-center relative">
          <button
            onClick={openDetailDialog}
            className="bg-primary w-[40%] mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
          >
            Click Here
          </button>

          <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
        </div>

        {detailsShowDialog && (
          <DialogBidderDetails id={tednerId} onClose={closeDetailDialog} />
        )}
      </div>
      <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-6 right-[27%]">
        <div className="min-h-[110px] min-w-[150px]">
          <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
            Bid Evaluation <br /> Summary Report
          </h2>
        </div>
        <div className="flex items-center relative">
          <button
            onClick={bidEvaluationSummaryReport}
            className="bg-primary w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
          >
            Create
          </button>
          <button
            onClick={bidEvaluationSummaryReportView}
            className="bg-[#797C80] w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10 ml-2"
          >
            View
          </button>

          <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
        </div>
      </div>

      <div className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between card min-w-[350px] absolute top-[35%] right-[27%]">
        <div className="min-h-[110px] min-w-[150px] flex justify-between">
          <div>
            <h2 className="text-[35px] text-[#797C80] font-semibold mb-1">
              Meeting <br /> Report
            </h2>
          </div>
          <div>
            <button onClick={openMeetingReportDialog}>
              <img
                src={uploadImage}
                alt="uploadImage"
                className="h-20 w-20 mt-2"
              />
            </button>
          </div>
          {showMeetingsDialog && (
            <DialogMeetingReportUp
              onClose={closeMeetingReportDialog}
              data={tednerId}
            />
          )}
        </div>

        <div className="flex items-center relative">
          <button
            onClick={createCommiteeMeetingReport}
            className="bg-primary w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10"
          >
            Create
          </button>

          <button
            onClick={viewCommiteeMeetingReport}
            className="bg-[#797C80] w-auto mt-2 text-white font-bold py-2 px-4 rounded-[22px] z-10 ml-2"
          >
            View
          </button>

          <div className="bg-primary rounded-[64px] w-4 h-10 absolute transform-translate-y-1/2 right-0 top-0"></div>
        </div>

        {/* {detailsShowDialog && (
          <DialogBidderDetails onClose={closeDetailDialog} />
        )} */}
      </div>
      <div className="mt-6 ml-10">
        {/*Search Box*/}
        <div className="flex flex-col font-poppins font-bold mt-10 ml-6 ">
          <form className="relative">
            <div className="flex items-center relative">
              <button className="absolute left-2 -translate-y-1/2 top-1/2 p-1">
                <svg
                  className="w-5 h-5 text-gray-700"
                  aria-labelledby="search"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  height="16"
                  width="17"
                >
                  <path
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    strokeWidth="1.333"
                    stroke="currentColor"
                    d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                  ></path>
                </svg>
              </button>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
              />
              <button
                type="button"
                className="btn btn-primary text-[#797C80] hover:bg-gray-100 ml-2"
                onClick={handleReset}
              >
                RESET
              </button>
            </div>
            <div className="mt-4 relative">
              {isDropdownOpen && (
                <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto z-10">
                  {tenders.length > 0 ? (
                    tenders.map((result) => (
                      <li
                        key={result.pr_id}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        value={selectedTender}
                        onClick={() => handleOptionClick(result)}
                      >
                        {result.pr_id}
                      </li>
                    ))
                  ) : (
                    <li className="p-2 text-gray-500">No results found</li>
                  )}
                </ul>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="mt-2">
        <div className="bg-[#F3F8FF] rounded-[46px] p-4 mt-6 ml-10 min-w-[45%] shadow-md  card absolute grid grid-rows-2  gap-6 text-[20px] font-bold ">
          <div className="w-[600px] flex items-center justify-between ">
            <label>Purchase Request No:</label>
            <input
              className="input-1 border border-[#00000036]"
              value={tednerId}
              onChange={(e) => setTenderId(e.target.value)}
              disabled
            ></input>
          </div>
          <div className="w-[600px] flex items-center justify-between ">
            <label>Purchase Request Name :</label>
            <input
              className="input-1 border border-[#00000036]"
              value={tenderName}
              onChange={(e) => setTenderName(e.target.value)}
              disabled
            ></input>
          </div>
          <div className="w-[620px] flex flex-col space-y-4">
            <div className="flex justify-between items-center">
              <label className="mr-4">Technical Evaluation Committee :</label>
              {techEvaCom1 ? ( // Check if techEvaCom has a value
                <input
                  className="input-1 border border-[#00000036]"
                  value={`${techEvaCom1} - ${techEvaComName}`} // Show selected value
                  disabled
                />
              ) : (
                <select
                  value={techEvaCom}
                  onChange={(e) => {
                    setTechEvaCom(e.target.value);
                    // Assuming you want to set techEvaComName as well
                    const selectedCommittee = committees.find(
                      (item) => item.committee_id === e.target.value
                    );
                    setTechEvaComName(
                      selectedCommittee ? selectedCommittee.committee_title : ""
                    );
                  }}
                  className="input-1 border border-[#00000036] w-full"
                >
                  <option value="">Select the Committee</option>
                  {committees && committees.length > 0 ? (
                    committees.map((item, index) => (
                      <option key={index} value={item.committee_id}>
                        {item.committee_id} - {item.committee_title}
                      </option>
                    ))
                  ) : (
                    <option>No committees available</option>
                  )}
                </select>
              )}
            </div>

            {techEvaCom1 ? null : ( // Only show the button if techEvaCom is not set
              <div className="flex justify-end">
                <button
                  type="submit"
                  onClick={handleAddCommittee}
                  className="bg-primary min-w-40 px-4 py-2 text-white font-bold rounded-[22px]"
                >
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-[20%]">
        <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
          Add Evaluation Criteria
        </p>
      </div>

      <div className="grid grid-rows-2">
        <div className="bg-[#F3F8FF] rounded-[46px] p-4 mt-6 ml-10 min-w-[90%] shadow-md justify-between card absolute grid grid-cols-5 grid-rows-2 gap-6 text-[20px] font-bold">
          <label>Criteria:</label>
          <input
            className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            value={criteriaName}
            onChange={(e) => setCriteriaName(e.target.value)}
            required
          />
          <label>Percentage</label>
          <input
            className="input ml-[8%] border border-gray-300 rounded-[22px] text-[#656565] text-center"
            placeholder="10"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
            type="number"
            min="0" // Ensure no negative values
            required
          />
          {hasPermission(2654) && (
            <button
              onClick={handleAdd}
              className="bg-primary max-w-[50%] text-white font-bold right-6 rounded-[22px]"
            >
              Add
            </button>
          )}
        </div>

        <div className="flex flex-between font-poppins font-bold mt-6 ml-10 "></div>
        <div className="mt-[4%] flex justify-between">
          <p className="text-[32px] font-sans ml-6 font-bold text-[#071C50]  ">
            Evaluation of the Bidders.
          </p>
          {hasPermission(2655) && (
            <button
              // onClick={openDialog}
              className="bg-primary min-w-40 text-white font-bold mr-10 rounded-[22px] "
            >
              Export as CSV
            </button>
          )}
        </div>
      </div>

      <TableBidderEvaluation
        quotations={quotations}
        criteriaList={criteriaList}
        tenderId={tednerId}
      />
    </div>
  );
};

export default Technical_Members;
