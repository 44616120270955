import React from 'react';

const JournalEntryDetailModal = ({ entry, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-2/3">
                <h2 className="text-2xl font-bold mb-4">Journal Entry Details</h2>
                {entry ? (
                    <>
                        <p><strong>Entry Type:</strong> {entry.entry_type}</p>
                        <p><strong>Reference Number:</strong> {entry.reference_number}</p>
                        <p><strong>Entry Date:</strong> {new Date(entry.entry_date).toLocaleDateString()}</p>
                        <p><strong>Status:</strong> {entry.status}</p>
                        <p><strong>Payment Method:</strong> {entry.payment_method}</p>
                        <p><strong>Description:</strong> {entry.description}</p>

                        {/* Journal Entry Details Table */}
                        <h3 className="text-xl font-semibold mt-6">Journal Entry Details</h3>
                        <table className="min-w-full bg-white border border-gray-300 mt-4">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border">Account ID</th>
                                <th className="py-2 px-4 border">Amount</th>
                                <th className="py-2 px-4 border">Type</th>
                                <th className="py-2 px-4 border">Party Type</th>
                                <th className="py-2 px-4 border">Party ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {entry.journal_entry_details.map((detail, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border">{detail.account_id}</td>
                                    <td className="py-2 px-4 border">{detail.amount}</td>
                                    <td className="py-2 px-4 border">{detail.type}</td>
                                    <td className="py-2 px-4 border">
                                        {detail.party_type_id ? detail.party_type_id : ''}
                                    </td>
                                    <td className="py-2 px-4 border">
                                        {detail.party_id ? detail.party_id : ''}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                ) : (
                    <p>No entry selected</p>
                )}

                <button
                    className="mt-4 px-4 py-2 bg-black text-white rounded"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default JournalEntryDetailModal;
