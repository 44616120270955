/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import for anchor tag if needed
import usePermissions from "../../../../components/permissions/permission";

const TableInsideCard = ({ data, navigate, statusPR, subCategoryId }) => {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [pendingPR, setPendingPR] = useState([]);
  const { hasPermission } = usePermissions();

  const handleViewClick = (item) => {
    navigate("/purchase/viewPurchaseRequest/pending", {
      state: {
        selectedRecord: item,
        statusPR: statusPR, // Pass statusPR to the next route
      },
    });
  };

  const getButtonStyle = (status) => {
    switch (status) {
      case "APPROVED":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "REJECTED":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-[#ffb703] text-white border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      default:
        return ""; // Default style
    }
  };

  useEffect(() => {
    const status = statusPR === "Approved" ? "approved_img" : "prepared_img"; // dynamically change status
    const fetchPendingPR = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplychain/purcaseRequestApprove/subject-approvel-get?category=${status}`
        );
        const data = await response.json();
        setPendingPR(data);
      } catch (error) {
        console.error("Error fetching pending PR:", error);
      }
    };

    fetchPendingPR();
  }, []);

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 max-h-[400px] overflow-y-auto">
      <table className="w-full border-collapse border-none">
        <thead>
          <tr className="text-left bg-[#FFF] ">
            <th className="px-4 py-2 font-normal">PR No</th>
            <th className="px-4 py-2 font-normal">Request Title</th>
            <th className="px-4 py-2 font-normal">Request Date</th>
            <th className="px-4 py-2 font-normal">Department</th>
            <th className="px-4 py-2 font-normal">Status</th>
            <th className="px-4 py-2 font-normal">Action</th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {pendingPR && pendingPR.length > 0 ? (
            pendingPR.map((item, index) => (
              <tr key={index}>
                <td className="px-4 py-2">{item.pr_id}</td>
                <td className="px-4 py-2">{item.title}</td>
                <td className="px-4 py-2">{item.required_date}</td>
                <td className="px-4 py-2">{item.department_name}</td>
                <td className="px-4 py-2">
                  <button
                    className={`inline-block ${getButtonStyle(
                      item.pr_department_approve_status
                    )} text-[#5B6D49]`}
                  >
                    {item.pr_department_approve_status}
                  </button>
                </td>
                <td className="px-4 py-2">
                  {hasPermission(2600) && (
                    <button
                      className="text-approveStattxt hover:underline"
                      onClick={() => handleViewClick(item)}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center px-4 py-2">
                No pending purchase requests prepared.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableInsideCard;
