/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";

const TableBidderEvaluation = ({ quotations, criteriaList, tenderId }) => {
  const [supplierScores, setSupplierScores] = useState({});
  const [submittedData, setSubmittedData] = useState([]); // Store the fetched submitted data
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the already submitted data
  const fetchSubmittedData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/supplychain/tender_evaluation_criteria_value/${tenderId}`
      );
      setSubmittedData(response.data);
      console.log("Submitted Data:", response.data); // Log the submitted data
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching submitted data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tenderId) {
      fetchSubmittedData();
    }
  }, [tenderId]);

  // Check if a supplier has already submitted a value for a given criteria
  const getSubmittedValue = (supplierId, criteriaId) => {
    // Convert supplierId and criteriaId to strings for comparison consistency
    const supplierIdStr = String(supplierId);
    const criteriaIdStr = String(criteriaId);

    // Debugging logs
    console.log("Looking for submitted value:");
    console.log("Supplier ID:", supplierIdStr);
    console.log("Criteria ID:", criteriaIdStr);

    // Find matching submitted data
    const submittedEntry = submittedData.find(
      (data) =>
        String(data.bidder_id) === supplierIdStr &&
        String(data.criteria_id) === criteriaIdStr
    );

    // Log if no match is found
    if (!submittedEntry) {
      console.warn(
        `No match found for supplier_id: ${supplierIdStr} and criteria_id: ${criteriaIdStr}`
      );
    }

    return submittedEntry ? submittedEntry.criteria_value : null;
  };

  // Handle input changes
  const handleInputChange = (supplierId, criteriaId, value) => {
    setSupplierScores((prevScores) => ({
      ...prevScores,
      [supplierId]: {
        ...prevScores[supplierId],
        [criteriaId]: value,
      },
    }));
  };

  // Calculate total score for each supplier
  const calculateTotalScore = (supplierId) => {
    const scores = supplierScores[supplierId] || {};
    const submittedScores = submittedData
      .filter((data) => data.bidder_id === supplierId)
      .reduce((acc, data) => {
        acc[data.criteria_id] = parseFloat(data.criteria_value);
        return acc;
      }, {});

    const allScores = { ...submittedScores, ...scores };
    return Object.values(allScores).reduce(
      (acc, score) => acc + (parseFloat(score) || 0),
      0
    );
  };

  // Submit the filled table data to the API
  const handleSubmit = async () => {
    const formattedData = {
      tender_id: tenderId,
      values: [],
    };

    // Loop through the supplier scores to format the data
    Object.keys(supplierScores).forEach((supplierId) => {
      Object.keys(supplierScores[supplierId]).forEach((criteriaId) => {
        formattedData.values.push({
          bidder_id: supplierId,
          criteria_id: criteriaId,
          criteria_value: supplierScores[supplierId][criteriaId],
        });
      });
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/supplyChain/tender_evaluation_criteria_value`,
        formattedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        alert("Data submitted successfully!");
        fetchSubmittedData(); // Refresh the submitted data after submission
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("An error occurred while submitting the data.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4 w-[96%] ml-10 max-h-[100%] overflow-y-auto mb-8 mt-6">
      <table className="w-full border-collapse border-none min-h-[250px]max-h-[50%] overflow-y-auto">
        <thead>
          <tr className="text-left bg-white text-primary font-bold">
            <th className="px-6 py-6">Criteria</th>
            {quotations.length > 0 &&
              quotations[0]?.suppliers?.length > 0 &&
              quotations[0].suppliers.map((supplier, index) => (
                <th key={index} className="px-6 py-6">
                  {supplier.supplier_name}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="font-bold">
          {criteriaList.length > 0 ? (
            criteriaList.map((criteria) => (
              <tr key={criteria._id} className="border-b border-gray-300">
                <td className="px-6 py-2">
                  {criteria.criteria} ({criteria.percentage}%)
                </td>

                {/* Input fields for each supplier */}
                {quotations.length > 0 &&
                  quotations[0]?.suppliers?.length > 0 &&
                  quotations[0].suppliers.map((supplier) => {
                    const submittedValue = getSubmittedValue(
                      supplier.supplier_id,
                      criteria._id
                    );
                    return (
                      <td key={supplier.supplier_id} className="px-6 py-2">
                        {submittedValue ? (
                          <input
                            type="number"
                            className="border border-gray-300 rounded-[22px] text-center bg-gray-200"
                            value={submittedValue}
                            disabled
                          />
                        ) : (
                          <input
                            type="number"
                            min="0"
                            className="border border-gray-300 rounded-[22px] text-center"
                            value={
                              supplierScores[supplier.supplier_id]?.[
                                criteria._id
                              ] || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                supplier.supplier_id,
                                criteria._id,
                                e.target.value
                              )
                            }
                          />
                        )}
                      </td>
                    );
                  })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={quotations.length + 1} className="px-6 py-2">
                No criteria found.
              </td>
            </tr>
          )}

          {/* Total row */}
          <tr className="font-bold bg-gray-100">
            <td className="px-6 py-2">Total</td>
            {quotations.length > 0 &&
              quotations[0]?.suppliers?.length > 0 &&
              quotations[0].suppliers.map((supplier) => (
                <td key={supplier.supplier_id} className="px-6 py-2">
                  {calculateTotalScore(supplier.supplier_id)}
                </td>
              ))}
          </tr>
        </tbody>
      </table>

      <button
        onClick={handleSubmit}
        className="bg-primary mt-6 text-white font-bold py-2 px-4 rounded-[22px]"
      >
        Submit New Values
      </button>
    </div>
  );
};

export default TableBidderEvaluation;
